export const basePx = 16
export const pxToRem = (px: number) => `${px / basePx}rem`
export const deviceSize = {
  tablet: 580,
  tabletXL: 991,
  laptop: 1280,
  desktop: 1540,
}
const monrope = 'Manrope, sans-serif '

export const font = {
  monrope: monrope,
}
export default {
  pxToRem,
  size: {
    tablet: `${deviceSize.tablet}px`,
    tabletXL: `${deviceSize.tabletXL}px`,
    laptop: `${deviceSize.laptop}px`,
    desktop: `${deviceSize.desktop}px`,
  },
  device: {
    tablet: `screen and (min-width: ${deviceSize.tablet + 1}px)`,
    tabletMax: `screen and (max-width: ${deviceSize.tablet + 1}px)`,
    tabletXL: `screen and (min-width: ${deviceSize.tabletXL + 1}px)`,
    tabletMaxXL: `screen and (max-width: ${deviceSize.tabletXL + 1}px)`,
    laptop: `screen and (min-width: ${deviceSize.laptop + 1}px)`,
    desktop: `screen and (min-width: ${deviceSize.desktop + 1}px)`,
  },
  color: {
    primary: '#00A099',
    primary10: 'rgba(13, 131, 139, .10)',
    grey300: '#E0E0E0',
    grey700: '#616161',
    grey800: '#424242',
    grey850: '#303030',
    grey900: '#212121',
    black: '#000000',
    gray: '#AAAAAA',
    white: '#FFFFFF',
  },
  font: font,
  globalPadding: {
    topMobile: '95px',
    topTabletXL: '95px',
    mobileLeftRight: '16px',
    tabletLeftRight: '60px',
    tabletXLLeftRight: '76px ',
    laptopLeftRight: '156px',
  },
}
