import { Global, css } from '@emotion/react';
import '../../static/fonts/fonts.css'
import variables, { font } from './variables'
import { normalize } from 'styled-normalize'
import React from 'react';

export const GlobalStyle = () => (
  <Global
    styles={css`
      ${normalize}
      html {
        font-family: ${font.monrope};
        margin: 0;
        padding: 0;
        max-width: 100vw;
        overflow-x: hidden;
      }
      body {
        max-width: 100vw;
        overflow-x: hidden;
      }

      .global__container {
        max-width: ${variables.pxToRem(1162)};
        padding: 0px 16px;
        margin: auto;
        box-sizing: border-box;
        @media ${variables.device.tabletMaxXL} {
          width: 100%;
          padding: 0px 60px;
        }
        @media ${variables.device.tabletMax} {
          padding: 0px 16px;
        }
      }

      .display-md {
        @media ${variables.device.tabletMax} {
          display: none;
        }
      }

      .display-xs {
        @media ${variables.device.tablet} {
          display: none;
        }
      }
    `}
  />
);
